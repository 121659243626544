<template>
  <v-row class="elevation-form mr-md-1 py-2 px-1">
    <v-col cols="12" class="mb-0 pb-0">
      <h3 class="font-weight-bold mb-1">Email subject</h3>
      <v-text-field filled dense v-model="$parent.attributes.subject" @input="setBtn"></v-text-field>
    </v-col>
    <v-col cols="12" class="my-0 py-0">
      <v-row class="my-0 py-0">
        <v-col cols="6">
          <h3 class="font-weight-bold">Email body</h3>
        </v-col>
        <v-col cols="6" class="text-right">
          <v-btn class="mx-1" @click="setBtnEditor" :color="btnType === 'editor' ? 'primary' : 'white'">
            <span class="font-weight-medium">
              <v-icon size="16px">mdi-pencil</v-icon>
              Editor
            </span>
          </v-btn>
          <v-btn class="mx-2" @click="setBtnHtml" :color="btnType === 'html' ? 'primary' : 'white'">
            <span class="font-weight-medium">
              <v-icon size="16px">mdi-code-tags</v-icon>
              HTML
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <ContentInput title="" subtitle="Maximum 100.000 characters" v-if="btnType === 'editor'" :model="'bodyHtml'" />
      <!-- <TextEditor
        :callback="$parent.setContent"
        title=""
        subtitle=""
        ref="editor"
        :value="$parent.attributes.html"
        v-if="btnType === 'editor'"
      /> -->
      <!-- <v-btn @click="addText">test</v-btn> -->
      <codemirror class="mt-3" :options="cmOptions" v-model="attributes.bodyHtml" v-if="btnType === 'html'" />
    </v-col>
  </v-row>
</template>

<script>
// import ContentInput from '@/views/website/components/Content';
// import TextEditor from '@/components/form/Wysiwyg';
import ContentInput from '@/views/website/components/Editor';

import { codemirror } from 'vue-codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/3024-night.css';
import EventBus from '@/plugins/event-bus';
export default {
  components: {
    ContentInput,
    codemirror,
    // TextEditor,
  },
  props: {
    isChange: Boolean,
  },
  data() {
    return {
      btnType: 'editor',
      cmOptions: {
        tabSize: 4,
        mode: 'text/javascript',
        // theme: '3024-night',
        lineNumbers: true,
        line: true,
      },
      attributes: {
        subject: ' Order {{ order_name}} confirmed ',
        bodyHtml: `

{{ email_title := "Thank you for your purchase! "}}


<!DOCTYPE html>
<html lang="en" xmlns:o="urn:schemas-microsoft-com:office:office">
  <head>
    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
    <meta name="viewport" content="width=device-width,initial-scale=1" />
    <meta name="x-apple-disable-message-reformatting" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <title>{{ email_title }}</title>

    <style type="text/css">

      @media only screen and (min-width: 1px) {
        .serif {font-family: -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;}
        .sans-serif {font-family: -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;}
      }

      #outlook a {padding: 0;}
      a {text-decoration: none;}
      table {border-collapse: collapse;}
      .column {font-size: 13px; line-height: 23px;}
      .column, h1, h2, h3, h4, h5, h6 {font-family: Arial,sans-serif;}
      img {border: 0; display: block; line-height: 100%;}

      .wrapper {min-width: 600px;}
      .row {margin: 0 auto; width: 600px;}
      .row .row, th .row {width: 100%;}

      @media only screen and (max-width: 699px) {

        .wrapper {min-width: 100% !important;}
        .row {width: 90% !important;}
        .row .row {width: 100% !important;}

        .column {
          box-sizing: border-box;
          display: inline-block !important;
          line-height: inherit !important;
          width: 100% !important;
          word-break: break-word;
          -webkit-text-size-adjust: 100%;
        }

        .mobile-4  {max-width: 33.33333%;}
        .mobile-5  {max-width: 41.66667%;}
        .mobile-6  {max-width: 50%;}
        .mobile-7  {max-width: 58.33333%;}
        .mobile-8  {max-width: 66.66667%;}
        .mobile-12 {
          padding-right: 24px !important;
          padding-left: 24px !important;
        }

        .mobile-offset-4  {margin-left: 33.33333% !important;}

        .has-columns {
          padding-right: 20px !important;
          padding-left: 20px !important;
        }

        .has-columns .column {
          padding-right: 10px !important;
          padding-left: 10px !important;
        }

        img {
          width: 100% !important;
          height: auto !important;
        }

        .mobile-center {
          display: table !important;
          float: none;
          margin-left: auto !important;
          margin-right: auto !important;
        }

        .mobile-text-center {text-align: center !important;}
        .mobile-text-left   {text-align: left !important;}
        .mobile-valign-top  {vertical-align: top !important;}

        .mobile-full-width {
          display: table;
          width: 100% !important;
        }

        .spacer {height: 30px; line-height: 100% !important; font-size: 100% !important;}

        .mobile-padding-top {padding-top: 30px !important;}
        .mobile-padding-top-mini {padding-top: 10px !important;}
        .mobile-padding-bottom {padding-bottom: 30px !important;}
        .mobile-margin-top-mini {margin-top: 10px !important;}
        .mobile-margin-bottom-mini {margin-bottom: 10px !important;}
      }
    </style>
  </head>
  <body style="box-sizing:border-box;margin:0;padding:0;width: 100%;-webkit-font-smoothing:antialiased;">

    <table class="wrapper" align="center" bgcolor="#EEEEEE" cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <td style="padding: 30px 0;">

          <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
            <tr>
              <th class="column mobile-12" width="640" style="padding-left: 24px; padding-right: 24px; text-align: left; padding-top: 24px;">
                {{#if shop.email_logo_url }}
                <a href="{{ shop.url }}" style="text-decoration: none;">
                  <img src="{{ shop.email_logo_url }}" width="{{ shop.email_logo_width }}" alt="{{ shop.name }}" style="border: 0; width: 100%; max-width: {{ shop.email_logo_width }}px;">
                </a>
                {{else}}
                <h1 style="margin: 0;">
                  <a href="{{ shop.url }}" class="serif" style="color: {{#if shop.email_accent_color }}{{ shop.email_accent_color }}{{else}}#363e43{{/if }}; text-decoration: none;">{{ shop.name }}</a>
                </h1>
                {{/if}}
              </th>
            </tr>
            <tr>
              <th class="column mobile-12 mobile-padding-top-mini" width="640" style="padding-left: 24px; padding-right: 24px; padding-top: 12px; text-align: left;">
                <div class="sans-serif" style="color: #6e7a81; font-size: 12px; font-weight: bold;">ORDER {{ order_name }}</div>
              </th>
            </tr>
          </table>

          <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0" style="margin-bottom: 16px">
            <tr>
              <td class="spacer" height="24" style="font-size: 24px; line-height: 24px;">&nbsp;</td>
            </tr>
            <tr>
              <th class="column" width="640" style="padding-left: 24px; padding-right: 24px; font-weight: 400; text-align: left;">
                <div class="serif" style="color: #363e43; font-size: 24px; font-weight: bold; letter-spacing: -0.25px; line-height: 30px; margin-bottom: 8px;">{{ email_title }}</div>
                <div class="sans-serif" style="color: #363e43; font-size: 14px; line-height: 24px;">{{#if requires_shipping }}Hi {{#if customer.first_name }}{{ customer.first_name }}{{else }}there{{/if}}, we're getting your order ready to be shipped. We will notify you when it has been sent.{{/if }}</div>
                {{#if order_status_url }}
                <div class="spacer" style="font-size: 24px; line-height: 24px;">&nbsp;</div>
                <table class="mobile-full-width" bgcolor="{{#if shop.email_accent_color }}{{ shop.email_accent_color }}{{else }}#0093ed{{/if }}" cellpadding="0" cellspacing="0" style="display: table; width: 100% !important; border-radius: 3px;text-align: center;">
                  <tr>
                    <th>
                      <a href="{{ order_status_url }}" class="sans-serif" style="border: 0 solid {{#if shop.email_accent_color }}{{ shop.email_accent_color }}{{else}}#0093ed{{/if}}; color: #FFFFFF; display: block; font-size: 14px; font-weight: 400; padding: 15px 25px 15px 25px; text-decoration: none;">View your order</a>
                    </th>
                  </tr>
                </table>
                <table class="mobile-collapsed" cellpadding="0" cellspacing="0" style="display: table; width: 100% !important; text-align: center; margin-top: 16px;">
                  <tr>
                    <th class="sans-serif" style="padding-left: 10px;font-weight: 400;font-size: 13px; line-height: 23px;text-align: center;">
                      Or
                      <a href="{{ shop.url }}" class="sans-serif" style="color: {{#if shop.email_accent_color }}{{ shop.email_accent_color }}{{else }}#1F2225{{/if}}; text-decoration: none;">Visit our store</a>
                    </th>
                  </tr>
                </table>
                {{/if }}
              </th>
            </tr>
            <tr>
              <td class="spacer" height="24" style="font-size: 24px; line-height: 24px;">&nbsp;</td>
            </tr>
          </table>

          <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
            <tr>
              <td class="spacer" height="24" style="font-size: 24px; line-height: 24px;">&nbsp;</td>
            </tr>
            <tr>
              <th class="column" width="640" style="padding-left: 24px; padding-right: 24px; font-weight: 400; text-align: left;">
                <div class="serif" style="color: #363e43; font-size: 22px; font-weight: bold;">Order summary</div>
              </th>
            </tr>
            <tr>
              <td class="spacer" height="24" style="font-size: 24px; line-height: 24px;">&nbsp;</td>
            </tr>
          </table>

            <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
              <tr class="mobile-valign-top">
                <th class="column mobile-4" width="120" style="padding-left: 24px; padding-right: 10px; text-align: left;">
                  <img src="{{ img_url_200_200 }}" width="100" alt="{{#if product.title }}{{ product.title }}{{else }}{{ title }}{{/if }}" style="border: 0; width: 100%;">
                </th>
                {{ line_display := 5 }}
                <th class="column mobile-4" width="255" style="padding-left: 10px; padding-right: 10px; font-weight: 400; text-align: left;">
                  <div class="serif" style="color: #363e43; font-size: 16px; font-weight: 700;">{{#if product.title }}{{ product.title }}{{else }}{{ title }}{{/if }} x {{ line_display }}</div>
				            {{#if variant_title }}
                  <div class="mobile-margin-top-mini sans-serif" style="color: #969AA1; font-size: 13px; line-height: 20px; margin-top: 10px;">{{ variant_title }}</div>
                    {{/if }}
				            {{#if product.description }}
                  <div class="mobile-margin-top-mini sans-serif" style="color: #969AA1; font-size: 13px; line-height: 20px; margin-top: 10px;">{{ product.description }}</div>
                  {{else }}
                  <div class="sans-serif" style="color: #969AA1; font-size: 13px; line-height: 20px; margin-top: 10px;">{{ price }}</div>
                  {{/if }}
				          {{#if custom_options }}
							<div class="mobile-margin-top-mini sans-serif" style="color: #969AA1; font-size: 13px; line-height: 20px; margin-top: 10px;">
								{{ name }}: <a href="{{ validURL }}" title="{{ validURL }}">{{ value }}</a>
							</div>
						{{else }}
							<div class="mobile-margin-top-mini sans-serif" style="color: #969AA1; font-size: 13px; line-height: 20px; margin-top: 10px;">
								{{ name }}: {{ value }}
							</div>
						{{/if }}
                </th>
                <th class="column mobile-4" width="200" style="padding-left: 10px; padding-right: 24px;">
                  <div class="sans-serif" style="color: #363e43; font-size: 16px; line-height: 16px; text-align: right;">{{ line_price_with_currency }}</div>
                  <div class="sans-serif" style="color: #525c64; font-size: 13px; font-weight: 400; line-height: 13px; margin-bottom: 5px; text-align: right;">
                    <strike>{{ original_line_price_with_currency }}</strike>
                  </div>
                </th>
              </tr>
              <tr>
                <th class="column" colspan="3" width="640" style="padding-left: 24px; padding-right: 24px;">
                  <table class="divider" cellpadding="0" cellspacing="0" width="100%">
                    <tr>
                      <th height="32">
                        <div style="border-top: 1px solid #dbdfe1; font-size: 0; line-height: 0;">&nbsp;</div>
                      </th>
                    </tr>
                  </table>
                </th>
              </tr>
            </table>


          <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
            <tr>
              <th class="column" width="640" style="padding-left: 24px; padding-right: 24px;">
                      {{#each discounts as | discount |}}
                        {{#with discount_title := discount.code }}
                        <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
                          <tr>
                            <th class="column mobile-6" width="310" style="color: #525c64; padding-right: 10px; font-weight: 400; text-align: left;">
                              <div class="sans-serif" style="font-size: 16px;">{{ discount_title }}</div>
                            </th>
                            <th class="column mobile-6" width="310" style="color: #363e43; padding-left: 10px; text-align: right; font-weight: 700;">
                              <div class="sans-serif" style="font-size: 16px;">{{ discount.discounts_savings_with_currency }}</div>
                            </th>
                          </tr>
                        </table>
                        {{/with}}
                      {{/each}}
                  <div class="spacer" style="font-size: 10px; line-height: 10px; height: 10px;">&nbsp;</div>
                <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
                  <tr>
                    <th class="column mobile-6" width="310" style="color: #525c64; padding-right: 10px; font-weight: 400; text-align: left;">
                      <div class="sans-serif" style="font-size: 16px;">Subtotal</div>
                    </th>
                    <th class="column mobile-6" width="310" style="color: #363e43; padding-left: 10px; text-align: right; font-weight: 700;">
                      <div class="sans-serif" style="font-size: 16px;">{{ subtotal_price_with_currency }}</div>
                    </th>
                  </tr>
                </table>
                <div class="spacer" style="font-size: 10px; line-height: 10px; height: 10px;">&nbsp;</div>
                <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
                  <tr>
                    <th class="column mobile-6" width="310" style="color: #525c64; padding-right: 10px; font-weight: 400; text-align: left;">
                      <div class="sans-serif" style="font-size: 16px;">Shipping</div>
                    </th>
                    <th class="column mobile-6" width="310" style="color: #363e43; padding-left: 10px; text-align: right; font-weight: 700;">
                      <div class="sans-serif" style="font-size: 16px;">{{ shipping_price_with_currency }}</div>
                    </th>
                  </tr>
                </table>
                <div class="spacer" style="font-size: 10px; line-height: 10px; height: 10px;">&nbsp;</div>
                  <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
                    <tr>
                      <th class="column mobile-6" width="310" style="color: #525c64; padding-right: 10px; font-weight: 400; text-align: left;">
                        <div class="sans-serif" style="font-size: 16px;">{{ title }}</div>
                      </th>
                      <th class="column mobile-6" width="310" style="color: #363e43; padding-left: 10px; text-align: right; font-weight: 700;">
                        <div class="sans-serif" style="font-size: 16px;">{{ price_with_currency }}</div>
                      </th>
                    </tr>
                  </table>
                  <div class="spacer" style="font-size: 10px; line-height: 10px; height: 10px;">&nbsp;</div>
                <table class="divider" cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <th height="32">
                      <div style="border-top: 1px solid #dbdfe1; font-size: 0; line-height: 0;">&nbsp;</div>
                    </th>
                  </tr>
                </table>
                <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
                  <tr>
                    <th class="column mobile-5" width="175" style="padding-right: 10px; color: #525c64; text-align: left; vertical-align: top; font-weight: 400;">
                      <div class="sans-serif" style="font-size: 16px;">Total</div>
                    </th>
                    <th class="column mobile-7" width="505" style="padding-left: 10px; color: #1F2225; text-align: right;">
                      <div class="sans-serif" style="font-size: 24px; font-weight: 700;">{{ total_price_with_currency }}</div>
                    </th>
                  </tr>
                </table>
                <div class="spacer" style="font-size: 10px; line-height: 10px; height: 10px;">&nbsp;</div>
                  <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
                        <tr>
                          <th class="column mobile-6" width="175" style="padding-right: 10px; color: #525c64; text-align: left;">
                            <div class="sans-serif" style="font-size: 16px; font-weight: 400;">{{#if payment_details.credit_card_company }}{{ payment_details.credit_card_company }}{{else }}{{ gateway }}{{/if }}</div>
                          </th>
                          <th class="column mobile-6" width="505" style="padding-left: 10px; color: #363e43; text-align: right;">
                            <div class="sans-serif" style="font-size: 16px; font-weight: 700;">{{ amount_with_currency }}</div>
                          </th>
                        </tr>
                        <tr>
                          <th class="column mobile-6" width="175" style="padding-right: 10px; color: #525c64; text-align: left;">
                            <div class="sans-serif" style="font-size: 16px; font-weight: 400;">
                              Refund
                              <br>
                              <small>{{#if payment_details.credit_card_company }}{{ payment_details.credit_card_company }}{{else }}{{gateway}}{{/if }}</small>
                            </div>
                          </th>
                          <th class="column mobile-6" width="505" style="padding-left: 10px; color: #363e43; text-align: right;">
                            <div class="sans-serif" style="font-size: 16px; font-weight: 700;">- {{ amount_with_currency }}</div>
                          </th>
                        </tr>
                  </table>
                <div class="spacer" style="font-size: 24px; line-height: 24px;">&nbsp;</div>
              </th>
            </tr>
          </table>

          <table class="row" align="center"  bgcolor="#FFFFFF"cellpadding="0" cellspacing="0" style="margin-top: 16px;">
            <tr>
              <td class="spacer" height="24" style="font-size: 24px; line-height: 24px;">&nbsp;</td>
            </tr>
            <tr>
              <th class="column has-columns" width="640" style="padding-left: 24px; padding-right: 24px;">

                <table class="row" cellpadding="0" cellspacing="0">
                  <tr>
                    <th class="column" width="100%" style="font-weight: bold; text-align: left;">
                      <div class="serif" style="color: #363e43; font-size: 24px; margin-bottom: 16px; letter-spacing: -0.25px; line-height: 30px;">Customer information</div>
                    </th>
                  </tr>
                </table>

                <table class="row" cellpadding="0" cellspacing="0">
                  <tr>
                    {{#if requires_shipping }}{{#if shipping_address}}
                    <th class="column" width="310" style="color: #969AA1; font-size: 13px; font-weight: 400; text-align: left;">
                      <div class="sans-serif" class="mobile-margin-bottom-mini" style="color: #363e43; font-size: 16px; margin-bottom: 12px; font-weight: bold;">Shipping address</div>
                      <div class="sans-serif" class="sans-serif" style="font-size: 14px; color: #525c64; line-height: 24px;">{{ shipping_address }}</div>
                    </th>
                    {{/if }}
                    {{/if }}
                  </tr>
                </table>

                <div class="spacer" style="font-size: 20px; line-height: 20px;">&nbsp;</div>

                <table>
                  <tr>
                    {{#if billing_address }}
                    <th class="column mobile-padding-top" width="310" style="color: #969AA1; font-weight: 400; text-align: left;">
                      <div class="sans-serif" class="mobile-margin-bottom-mini" style="color: #363e43; font-size: 16px; margin-bottom: 12px; font-weight: bold;">Billing address</div>
                      <div class="sans-serif" style="font-size: 14px; color: #525c64; line-height: 24px;">{{ billing_address }}</div>
                    </th>
                    {{/if }}
                  </tr>
                </table>

                <div class="spacer" style="font-size: 20px; line-height: 20px;">&nbsp;</div>

                <table class="row" cellpadding="0" cellspacing="0">
                  <tr>
                    {{#if shipping_address }}{{#if shipping_address }}
                    <th class="column mobile-padding-bottom" width="310" style="color: #969AA1; font-size: 13px; font-weight: 400; text-align: left;">
                      <div class="mobile-margin-bottom-mini sans-serif" style="color: #363e43; font-size: 16px; margin-bottom: 12px; font-weight: bold;">Shipping method</div>
                      <div class="sans-serif" style="font-size: 14px; color: #525c64; line-height: 24px;">{{ shipping_method.title }}<br>{{ shipping_method.price_with_currency }}</div>
                    </th>
                    {{/if }}
                    {{/if }}
                  </tr>
                </table>

                <div class="spacer" style="font-size: 20px; line-height: 20px;">&nbsp;</div>

                <table>
                  <tr>
                        <th class="column" width="310" style="color: #969AA1; font-weight: 400; text-align: left; vertical-align: top;">
                          <div class="mobile-margin-bottom-mini sans-serif" style="color: #363e43; font-size: 16px; margin-bottom: 12px; font-weight: bold;">Payment method</div>
                                <div class="sans-serif" style="font-size: 14px; color: #525c64; line-height: 24px;">
                                  {{#if payment_details.credit_card_url }}
                                    <table cellpadding="0" cellspacing="0" width="100%">
                                      <tr>
                                        <td width="50"><img align="center" src="{{ payment_details.credit_card_url }}" width="38" style="width: 100%; max-width: 38px; display: inline-block; line-height: 100%; border: 0;"></td>
                                        <td style=" color: #969AA1; font-size: 14px;">Ending in {{ payment_details.credit_card_last_four_digits }} — {{ total_price_with_currency }}</td>
                                      </tr>
                                    </table>
                                  {{else}}
                                    {{ gateway }} - {{ amount_with_currency }}
                                  {{/if}}
                                </div>
                        </th>
                  </tr>
                </table>

              </th>
            </tr>
            <tr>
              <td class="spacer" height="24" style="font-size: 24px; line-height: 24px;">&nbsp;</td>
            </tr>
          </table>

          <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
            <tr>
              <td>
                <div style="border-top: 1px solid #dbdfe1; font-size: 0; line-height: 0;">&nbsp;</div>
              </td>
            </tr>
          </table>


          <table class="row" align="center" bgcolor="#FFFFFF" cellpadding="0" cellspacing="0">
            <tr>
              <th class="column has-columns" width="640" style="padding-left: 24px; padding-right: 24px;">

                <div class="spacer" style="font-size: 30px; line-height: 30px;">&nbsp;</div>

                <table class="row" cellpadding="0" cellspacing="0">
                  <tr>
                    <th class="column" width="640" style="color: #525c64; font-weight: 400; text-align: left; font-size: 14px; line-height: 20px;">
                      <div class="sans-serif">If you have any questions, reply to this email or contact us at <a href="mailto:{{ shop.email }}" style="color: {{#if shop.email_accent_color }}{{ shop.email_accent_color }}{{else }}#969AA1{{/if }}; text-decoration: underline;">{{ shop.email }}</a></div>
                    </th>
                  </tr>
                  <tr>
                    <td class="spacer" height="30" style="font-size: 30px; line-height: 30px;">&nbsp;</td>
                  </tr>
                </table>

              </th>
            </tr>
          </table>
        </td>
      </tr>
    </table>

  </body>
</html>
`,
      },
    };
  },
  methods: {
    setBtn() {
      this.$emit('setBtn', true);
    },
    setBtnEditor() {
      this.btnType = 'editor';
    },
    setBtnHtml() {
      this.btnType = 'html';
    },
  },
  watch: {
    'attributes.bodyHtml'() {
      this.$parent.setContent(this.attributes.bodyHtml);
    },
  },

  mounted() {
    this.attributes.bodyHtml = this.$parent.attributes.html;
  },
};
</script>

<style lang="scss">
.edit-text {
  color: white;
}
.CodeMirror {
  font-family: monospace;
  height: 400px;
  color: black;
  direction: ltr;
}
.row {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  flex: 1 1 auto !important;
  margin-right: 0px;
  margin-left: 0px;
}
.bg-template-variant {
  background: #7ed1ca;
  width: fit-content;
}
.bg-template-variant:hover,
.bg-template-variant:active {
  cursor: pointer;
}
</style>
