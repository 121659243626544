<template>
  <v-row class="elevation-form py-2 px-1">
    <v-col cols="12">
      <h3 class="font-weight-bold">Go template variables</h3>
      <span>
        You can use go template variables to output an accent colour and logo image in your templates. The available
        variables are:
      </span>
      <p class="mb-0 my-1 px-1 bg-template-variant" v-for="(item, index) in templateVariant" :key="index">
        <v-btn @click="addText(item.title)" small class="bg-template-variant btn-link">
          <span class="black--text">{{ item.title }}</span>
        </v-btn>
      </p>
    </v-col>
  </v-row>
</template>

<script>
import EventBus from '@/plugins/event-bus';
export default {
  data() {
    return {
      templateVariant: [
        {
          title: '{{ shop.name }}',
          href: '',
        },
        {
          title: '{{ shop.email }}',
          href: '',
        },
        {
          title: '{{ shop.email_logo_url }}',
          href: '',
        },
        {
          title: '{{ shop.email_logo_width }}',
          href: '',
        },
        {
          title: '{{ shop.email_accent_color }}',
          href: '',
        },
        // {
        //   title: '{{ lineitem_properties }}',
        //   href: '',
        // },
      ],
    };
  },
  methods: {
    addText1() {
      this.$parent.$refs.editor.addText();
    },
    addText(string) {
      EventBus.$emit('getTitle', string);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-template-variant {
  background: #7ed1ca;
  width: fit-content;
}
.bg-template-variant:hover,
.bg-template-variant:active {
  cursor: pointer;
}
</style>
