<template>
  <div class="notifications-router__page">
    <Redirect class="" title="Notifictions" route="website.settings.notifications" />
    <v-row v-if="!isLoading">
      <v-col cols="8">
        <p class="heading font-weight-bold mb-0">{{ attributes.name }}</p>
        <v-row>
          <v-col cols="6" md="3">
            <span class="notification-link" @click="sendTestEmail">
              Send test email
              <v-icon size="16px" color="primary">fas fa-question-circle</v-icon>
            </span>
          </v-col>
          <v-col cols="3" class="notification-link"><span @click="modal = true">Preview</span></v-col>
        </v-row>
      </v-col>
      <v-col cols="4" class="text-right">
        <div>
          <span class="px-4 mx-1 py-1 notifications-icon" @click="previousTemplate">
            <v-icon>fas fa-angle-left</v-icon>
          </span>
          <span class="px-4 mx-1 py-1 notifications-icon" @click="nextTemplate">
            <v-icon>fas fa-angle-right</v-icon>
          </span>
        </div>
        <v-btn
          deressed
          :color="isChange ? 'primary' : '#7ed1ca'"
          class="mt-4 px-13"
          :class="{ 'btn-no-hover': !isChange }"
          :loading="isLoadingAction"
          :disabled="isLoadingAction"
          @click="submit"
        >
          <span class="text-btn">Save</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!isLoading">
      <v-col cols="12" md="8" class="pr-md-2">
        <EmailModel @setBtn="setBtn" />
      </v-col>
      <v-col cols="12" md="4" class="pl-md-2">
        <TemplateVariant />
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>
    <v-row>
      <v-col cols="6">
        <v-btn deressed outlined :disabled="!isChange"> Rever to default </v-btn>
      </v-col>
      <v-col cols="6" class="text-right">
        <v-btn
          deressed
          :color="isChange ? 'primary' : '#7ed1ca'"
          class="mt-4 px-13"
          :class="{ 'btn-no-hover': !isChange }"
          :loading="isLoadingAction"
          :disabled="isLoadingAction"
          @click="submit"
        >
          <span class="text-btn">Save</span>
        </v-btn>
      </v-col>
    </v-row>
    <modal
      model="modal"
      title="Preview"
      :showFooter="false"
      titleButton="Delete"
      :callback="submitDelete"
      width="750px"
    >
      <template slot="content">
        <div v-html="body_html"></div>
      </template>
    </modal>
  </div>
</template>

<script>
import Redirect from '@/components/RedirectTo';
import EmailModel from './components/EmailModel';
import TemplateVariant from './components/TemplateVariant';
import { settingsApi } from '@/apis/settings';
import { log } from 'handlebars';
const Handlebars = require('handlebars');

export default {
  data() {
    return {
      modal: false,
      body_html: '',
      isChange: false,
      isLoading: false,
      isLoadingAction: false,
      notifications: {},
      attributes: {
        name: '',
        html: '',
      },
    };
  },
  components: {
    Redirect,
    EmailModel,
    TemplateVariant,
  },
  methods: {
    setContent(text) {
      if (!text.includes('<head>')) {
        text =
          `<head>
<style type="text/css">

      @media only screen and (min-width: 1px) {
        .serif {font-family: -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;}
        .sans-serif {font-family: -apple-system, system-ui, BlinkMacSystemFont, Roboto, Helvetica, Arial, sans-serif;}
      }

      #outlook a {padding: 0;}
      a {text-decoration: none;}
      table {border-collapse: collapse;}
      .column {font-size: 13px; line-height: 23px;}
      .column, h1, h2, h3, h4, h5, h6 {font-family: Arial,sans-serif;}
      img {border: 0; display: block; line-height: 100%;}

      .wrapper {min-width: 600px;}
      .row {margin: 0 auto; width: 600px;}
      .row .row, th .row {width: 100%;}
      .link_design { color: #0093ed !important;}
      .link_design:hover { color: #0093ed !important; text-decoration: underline !important;}
      @media only screen and (max-width: 699px) {

        .wrapper {min-width: 100% !important;}
        .row {width: 90% !important;}
        .row .row {width: 100% !important;}

        .column {
          box-sizing: border-box;
          display: inline-block !important;
          line-height: inherit !important;
          width: 100% !important;
          word-break: break-word;
          -webkit-text-size-adjust: 100%;
        }

        .mobile-4  {max-width: 33.33333%;}
        .mobile-5  {max-width: 41.66667%;}
        .mobile-6  {max-width: 50%;}
        .mobile-7  {max-width: 58.33333%;}
        .mobile-8  {max-width: 66.66667%;}
        .mobile-12 {
          padding-right: 24px !important;
          padding-left: 24px !important;
        }

        .mobile-offset-4  {margin-left: 33.33333% !important;}

        .has-columns {
          padding-right: 20px !important;
          padding-left: 20px !important;
        }

        .has-columns .column {
          padding-right: 10px !important;
          padding-left: 10px !important;
        }

        img {
          width: 100% !important;
          height: auto !important;
        }

        .mobile-center {
          display: table !important;
          float: none;
          margin-left: auto !important;
          margin-right: auto !important;
        }

        .mobile-text-center {text-align: center !important;}
        .mobile-text-left   {text-align: left !important;}
        .mobile-valign-top  {vertical-align: top !important;}

        .mobile-full-width {
          display: table;
          width: 100% !important;
        }

        .spacer {height: 30px; line-height: 100% !important; font-size: 100% !important;}

        .mobile-padding-top {padding-top: 30px !important;}
        .mobile-padding-top-mini {padding-top: 10px !important;}
        .mobile-padding-bottom {padding-bottom: 30px !important;}
        .mobile-margin-top-mini {margin-top: 10px !important;}
        .mobile-margin-bottom-mini {margin-bottom: 10px !important;}
      }
    </style>
</head>` + text;
      }
      this.attributes.html = text;
      this.isChange = true;
      this.$forceUpdate();
    },
    setBtn(boolean) {
      this.isChange = boolean;
    },
    setPopup() {
      this.$commit('setPopup', true);
      let index = this.$route.path.indexOf('/notifications/');
      console.log(index, this.$route.path);
    },
    submitDelete() {
      this.modal = false;
    },
    buildHTMLtemplate(html) {
      const template = Handlebars.compile(html);
      this.body_html = template(this.attributes.default_data);
    },
    async loadData() {
      try {
        this.isLoading = true;
        let data = await settingsApi.getNotifications();
        this.notifications = data.data;
        this.attributes = (await settingsApi.getNotificationsById(this.getTypeTemplate.id)).data;
        this.attributes.subject.replace('.', '/');
        this.buildHTMLtemplate(this.attributes.html);
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        console.log(error);
      }
    },
    async submit() {
      this.isLoadingAction = true;
      try {
        await settingsApi.updateNotifications(this.attributes);
        this.isLoadingAction = false;
        this.$store.commit('setMessages', {
          messages: 'Update success',
          type: 'success',
        });
      } catch (error) {
        this.isLoadingAction = false;
        this.$store.commit('setMessages', {
          messages: 'Update error',
          type: 'error',
        });
      }
    },
    async sendTestEmail() {
      try {
        const { status, data } = await settingsApi.sendTestEmail(this.attributes);
        if (status === 200) {
          this.$store.commit('setMessages', {
            messages: data.message,
            type: 'success',
          });
        } else {
          this.$store.commit('setMessages', {
            messages: data.message,
            type: 'error',
          });
        }
        console.log(data);
      } catch (error) {
        console.log(error);
      }
    },
    async previousTemplate() {
      this.isLoading = true;
      let index = this.notifications.findIndex((x, index) => {
        return this.attributes.type === x.type;
      });
      if (index > 0) {
        let prevNoti = this.notifications[index - 1];
        this.attributes = (await settingsApi.getNotificationsById(prevNoti.id)).data;
      }
      this.isLoading = false;
      this.buildHTMLtemplate(this.attributes.html);
    },
    async nextTemplate() {
      this.isLoading = true;
      let index = this.notifications.findIndex((x, index) => {
        return this.attributes.type === x.type;
      });
      let nextNoti = this.notifications[index + 1];
      this.attributes = (await settingsApi.getNotificationsById(nextNoti.id)).data;
      this.isLoading = false;
      this.buildHTMLtemplate(this.attributes.html);
    },
  },
  computed: {
    getTypeTemplate() {
      let type = this.$route.path.slice(this.$route.path.lastIndexOf('/') + 1, this.$route.path.length);
      return this.notifications.find(x => x.type === type);
    },
  },
  watch: {
    'attributes.html': function() {
      this.buildHTMLtemplate(this.attributes.html);
    },
  },
  mounted() {
    Handlebars.registerHelper('inc', function(value, options) {
      return parseInt(value) + 1;
    });
    // let a = " <div class='entry'> <h1>{{title}}</h1> <div class='body'>{{body}}</div></div>";
    // const template = Handlebars.compile(this.attributes.html);
    // console.log('this.attributes.html', this.attributes.html);
    // this.body_html = template(this.attributes.default_data);
    // console.log(this.body_html, 'abc');
  },
  async created() {
    await this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.row {
  width: 100% !important;
  display: flex !important;
  flex-wrap: wrap !important;
  flex: 1 1 auto !important;
  margin-right: 0px;
  margin-left: 0px;
}
.heading {
  font-size: 32px;
}
.notification-link {
  color: #a98a57;
}
.notification-link:active,
.notification-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.notifications-icon {
  background: rgba(219, 223, 225, 0.24);
  border: 1px solid rgba(145, 155, 161, 0.24);
  border-radius: 8px;
}
.notifications-icon:hover {
  cursor: pointer;
}
.text-btn {
  color: white;
}
.btn-no-hover {
  cursor: auto;
}
</style>
